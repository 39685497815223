import React from "react";

import { Training } from "../../assets/images/images"


const AboutTraining = () => {


    return (
        <div>
            <div className="flex pt-4 lg:pt-36 px-2 lg:px-16 pb-2 lg:pb-16 bg-[#050100]">
                <div className="text-white  mr-2 lg:mr-44 mb-0 ml-2 lg:ml-20">
                    <div className="text-m lg:text-xl font-cmedium leading-[3rem] mb-4">
                        Online & In-Person Training
                    </div>
                    <div className="leading-[3rem] font-clight ">
                    At the moment, I'm fully booked for one-on-one in-person and online training sessions and don't have any immediate openings. However, I'm keeping a waiting list, and I'll be sure to reach out once a slot becomes available. Feel free to provide your email if you'd like to join the list.
                    </div>
                </div>
                <div className="hidden lg:block">
                    <img src={Training} alt=""/>
                </div>
            </div>
        </div>
    )
};

export default AboutTraining;