import React, { useState } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { InstaImage, SpotifyImage, YoutubeImage, GoogleImage } from "../../assets/images/images"
import axios from 'axios';
import { GOOGLE_URL, SPOTIFY_URL, INSTAGRAM_URL, YOUTUBE_URL } from '../common/utils';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const backendUrl = 'https://mvnny-backend.onrender.com/submit-form'; 
    
        try {
            const response = await axios.post(backendUrl, formData);
    
            // Optionally, you can clear the form data after successful submission
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
            });
    
            alert('Form submitted successfully, I will get back to you shortly!');
            console.log('Form data submitted successfully:', response.data);
        } catch (error) {
            // If the error is coming from the response, log that, otherwise log the error object
            console.error('Error submitting form:', error.response ? error.response.data : error);
        }
    };
    


    // const containerStyle = {
    //     width: '600px',
    //     height: '400px'
    // };


    // const location = {
    //     lat: 40.756309,
    //     lng: -73.9981519
    // };
    // const markerIcon = {
    //     url: LocationImage,
    //     scaledSize: { width: 40, height: 45 }

    // };

    return (
        <div id="contact">
            <div className='flex flex-col lg:flex-row justify-between'>
                <div className=' bg-[#050100] py-3 px-2 my-1 mx-0 lg:m-3 rounded-lg w-full'>
                    <h1 className='text-white text-2xl font-bold mb-4 lg:ml-10 mb-3'>Get in touch</h1>
                    <div className='flex flex-col lg:flex-row justify-between'>
                        <form onSubmit={handleSubmit} className="ml-1 lg:ml-10 mt-4">
                            <div className='flex flex-col md:flex-row mb-2'>
                                <div className='flex mb-2'>
                                    <label className='text-white lg:mx-2 w-28 lg:w-40 h-8'>First Name:</label>
                                    <input
                                        type="text"
                                        className='w-full pl-2'
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='flex mb-2'>
                                    <label className='text-white lg:mx-2 w-28 lg:w-40  h-8'>Last Name:</label>
                                    <input
                                        type="text"
                                        className='w-full pl-2'
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col md:flex-row mb-2'>
                                <div className='flex mb-2'>
                                    <label className='text-white lg:mx-2 w-28 lg:w-40  h-8'>Email:</label>
                                    <input
                                        type="email"
                                        className='w-full pl-2'
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className=' flex'>
                                    <label className='text-white lg:mx-2 w-28 lg:w-40  h-8'>Phone:</label>
                                    <input
                                        type="tel"
                                        className='w-full pl-2'
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className='flex'>
                                <label className='text-white mr-4 lg:mr-1 lg:ml-2  w-28.5 lg:w-32'>Message:</label>
                                <textarea
                                    className='w-full h-24 pl-2'
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <button className='text-white text-center mt-6 ml-[60%] lg:ml-[93%]' type="submit">Submit</button>
                        </form>

                        {/* links */}
                        <div className='flex lg:flex-col -py-3 px-2 m-3 mt-6 lg:mt-0 rounded-lg space-x-4 justify-between'>
                            <div>
                                <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
                                    <img src={InstaImage} alt="Instagram" className="float-right w-12 mt-4 lg:mt-0" />
                                </a>
                            </div>
                            <div>
                                <a href={SPOTIFY_URL} target="_blank" rel="noopener noreferrer">
                                    <img src={SpotifyImage} alt='' className="float-right w-10 mr-1 mt-5 lg:mt-4" />
                                </a>
                            </div>
                            <div>
                                <a href={YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
                                    <div><img src={YoutubeImage} alt='' className="float-right w-12 mt-4" /></div>
                                </a>
                            </div>

                            <div>
                                <a href={GOOGLE_URL} target="_blank" rel="noopener noreferrer">
                                    <div> <img src={GoogleImage} alt='' className="float-right w-12 mt-4" /></div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Contact;
