import React from "react";
import { InstaImage, YoutubeImage, GoogleImage, SpotifyImage } from "../../assets/images/images"
import { GOOGLE_URL, SPOTIFY_URL, INSTAGRAM_URL, YOUTUBE_URL } from "./utils";
const BottomNav = () => {
    return (
        <div className="bg-black h-40 flex justify-between lg:justify-end px-2">
            <a href={GOOGLE_URL} target="_blank" rel="noopener noreferrer">
                <img src={GoogleImage} alt="GoogleImage" className="float-right w-9 lg:mr-20 mt-10" />
            </a>
            <a href={SPOTIFY_URL} target="_blank" rel="noopener noreferrer">
                <img src={SpotifyImage} alt="SpotifyImage" className="float-right w-7 lg:mr-20 mt-11" />
            </a>
            <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
                <img src={InstaImage} alt="Instagram" className="float-right w-9 lg:mr-20 mt-10" />
            </a>

            {/* <a href="https://www.instagram.com/_mvnny.fitness/" target="_blank" rel="noopener noreferrer">
                <img src={TwitterImage} alt="TwitterImage" className="float-right w-9 lg:mr-20 mt-10" />
            </a> */}
            <a href={YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
                <img src={YoutubeImage} alt="YoutubeImage" className="float-right w-9 lg:mr-20 mt-10" />
            </a>
            {/* <a href="https://www.instagram.com/_mvnny.fitness/" target="_blank" rel="noopener noreferrer">
                <img src={FacebookImage} alt="FacebookImage" className="float-right w-9 lg:mr-20 mt-10" />
            </a> */}
        </div>

    );
};

export default BottomNav;