import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import BlogPage from "./components/blog/BlogPage"
import MainPage from './components/main/MainPage';
import Header from "./components/common/Header"
import BottomNav from './components/common/BottomNav';


export default function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />}/>
        <Route path="/blog" element={<BlogPage />}/>
      </Routes>
      <BottomNav />
    </BrowserRouter>
  )
}

