import React from 'react';

import { SpotifyImage, GoogleImage, ytImage } from "../../assets/images/images"
import { GOOGLE_URL, SPOTIFY_URL, YOUTUBE_URL } from '../common/utils';

const Podcast = () => {

    return (
        <div id="podcast">
            <div className='flex flex-col lg:flex-row justify-between '>
                <div className='bg-[#050100]  flex flex-col lg:flex-row border-t border-red-500 pt-4 lg:pt-12  flex w-full pb-10'>
                    <div className='text-white text-2xl font-cmedium mb-4 ml-10 mt-6'>Listen to my Beyond the Exercise Podcast on all available platforms</div>
                    <div className='flex justify-between lg:ml-auto pl-4'>
                        <div className='mx-5'> 
                            <a href={YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
                                <img src={ytImage} alt="" className="float-right w-16 mt-3.5 pl-1.5" />
                            </a>
                        </div>
                        <div className='mx-6'> 
                            <a href={GOOGLE_URL} target="_blank" rel="noopener noreferrer">
                                <img src={GoogleImage} alt="" className="float-right w-13 mr-2" />
                            </a>
                        </div>
                        <div className='mr-10 mt-5'>
                            <a href={SPOTIFY_URL} target="_blank" rel="noopener noreferrer">
                                <img src={SpotifyImage} alt="" className="float-right w-12" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Podcast;
