import React from "react";


const BlogPage = () => {
    <div>
        Here will be a blog!
    </div>
}


export default BlogPage