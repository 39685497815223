import React from "react";
import { TrainingPlansImage, TrustedImage, TimeImage } from  "../../assets/images/images"

const Pillar = ({ title, text, icon }) => {
    return (
        <div className="hidden lg:block ml-8 mr-16 py-12 px-8 bg-white -mt-48 w-2/3 text-center h-72 border shadow-md">
            <div className="flex justify-center ">
                <img src={icon} alt="" className="w-[61px] mr-3" />
                <div className="self-center pt-3 leading-[28px] font-cbold font-semibold">{title}</div> </div>
            <div className="mt-4 font-clight">
                {text}
            </div>
        </div>
    )
}
const commitmentText = "The state or quality of being dedicated to a cause, activity, etc. The state of being emotionally or intellectually devoted, as to a belief, a course of action, or another person. An engagement or obligation that restricts freedom of action. A pledge to do."
const disciplineText = "The practice of training people to obey rules or a code of behavior. A system of rules of conduct."
const habitText = "A settled or regular tendency or practice, especially one that is hard to give up. A behavior pattern acquired by frequent repetition or physiologic exposure that shows itself in regularity or increased facility of performance."

const Pillars = () => {

    return (
        <div>
            <div className="flex bg-[#050100]">
                <Pillar title={"COMMITMENT"} text={commitmentText} icon={TrustedImage} />
                <Pillar title={"DISCIPLINE"} text={disciplineText} icon={TimeImage} />
                <Pillar title={"HABIT"} text={habitText} icon={TrainingPlansImage} />
            </div>
        </div>
    )
};

export default Pillars;