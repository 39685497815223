import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { LogoImage } from '../../assets/images/images';
import { GOOGLE_URL, SPOTIFY_URL, YOUTUBE_URL } from './utils';


const Header = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    let Links = [
        { name: "ABOUT", link: "#about" },
        { name: "CONTACT", link: "#contact" },
        { name: "TESTIMONIALS", link: "#testimonails" },
        // { name: "BLOG", link: "/blog" },
    ];
    let dropdowns = {
        name: "PODCAST", dropdown: [
            { name: "Youtube", link: YOUTUBE_URL },
            { name: "Spotify", link: SPOTIFY_URL },
            { name: "Google", link: GOOGLE_URL },
        ]
    },
        [open, setOpen] = useState(false);
    let navigate = useNavigate();

    const handleScroll = (link) => {
        if (link === "/blog") {
            navigate(link);
        } else {
            const element = document.querySelector(link);
            const rect = element.getBoundingClientRect();
            const headerHeight = 64; // Adjust this value according to your header's height
            const offsetTop = window.pageYOffset + rect.top - headerHeight;
    
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    }
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='shadow-md w-full fixed top-0 left-0 z-50'>
            <div className='md:flex items-center justify-between bg-black py-4 md:px-10 px-7'>
                {/* logo section */}
                <div className='font-bold text-2xl cursor-pointer flex items-center gap-1'>

                    <img src={LogoImage} alt="" className='lg:w-80 w-36' />
                </div>
                {/* Menu icon */}
                <div onClick={() => setOpen(!open)} className='absolute right-8 top-4 lg:top-6 cursor-pointer md:hidden w-7 h-7'>
                    {
                        open ? <XMarkIcon className='text-white' /> : <Bars3BottomRightIcon className='text-white' />
                    }
                </div>
                {/* link items */}
                <ul className={`flex flex-col bg-black lg:flex-row md:items-center md:pb-0 pb-12 absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'}`}>
                    {
                        Links.map((link) => (
                            <li className='md:ml-8 md:my-0 my-7 font-semibold'>
                                <a
                                    href={link.link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleScroll(link.link);
                                        setOpen(false);
                                    }}
                                    className='text-white hover:text-red-400 duration-500'
                                >
                                    {link.name}
                                </a>

                            </li>))
                    }
                    <li className='md:ml-8 md:my-0 my-7 font-semibold relative px-3'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setIsDropdownOpen(!isDropdownOpen);
                            }}
                            className='text-white hover:text-red-400 duration-500'
                        >
                            {dropdowns.name}
                        </button>
                        {isDropdownOpen && (
                            <ul className="absolute left-0 mt-2 bg-black rounded shadow-md" ref={dropdownRef}>
                                {dropdowns.dropdown.map(item => (
                                    <li className='my-2'>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     handleScroll(item.link);
                                            //     setIsDropdownOpen(false);
                                            // }}
                                            className='text-white hover:text-red-400 duration-500 px-4 py-2 block'
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Header;