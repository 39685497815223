import BioImageJpeg from './bio.jpeg'
import BioJpeg from './bio_img.jpeg'
import TrainingJpeg from './training.jpeg'
import Trusted from './trusted.png'
import Time from './time.svg'
import TrainingPlans from './training_plans.svg'
import Logo from './logo.png'
import Insta from './instagram.png'
import Twitter from './twitter.png'
import Youtube from './youtube.png'
import Facebook from './facebook.png'
import Self from './self.jpeg'
import Review from './review.png'
import Location from './location.png'
import Google from './google.svg'
import Spotify from './spotify.png'
import Contact from './contact.svg'
import yt from './youtube-white.webp'

export const ytImage = yt
export const ReviewImage = Review
export const SelfImage = Self
export const BioImage = BioImageJpeg
export const Bio = BioJpeg
export const Training = TrainingJpeg
export const TrustedImage = Trusted
export const TimeImage = Time
export const TrainingPlansImage = TrainingPlans
export const LogoImage = Logo
export const InstaImage = Insta
export const TwitterImage = Twitter
export const YoutubeImage = Youtube
export const FacebookImage = Facebook
export const LocationImage = Location
export const GoogleImage = Google
export const SpotifyImage = Spotify
export const ContactImage = Contact
