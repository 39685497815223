import React from "react";

import About from "./About";
import AboutTraining from "./AboutTraining"
import Intro from "./Intro"
import Pillars from "./Pillars"
import Testimonials from "./Testimonials"
import Contact from "./Contact"
import Podcast from "./Podcast"


const MainPage = () => {
    return (
        <>
            <Intro />
            <Podcast />
            <About />
            <Pillars />
            <AboutTraining />
            <Contact />
            <Testimonials />
        </>
    )
}

export default MainPage