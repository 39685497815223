import React from "react";

const About = () => {

    return (
        <div
            id="about"
            className="bg-[#7A7877] lg:pt-[4em] pt-2 lg:pr-[2em] pb-2 lg:pb-[20rem] pl-4 lg:pl-[2em] leading-[2rem] text-[1.5rem]"
        >
            <div className="font-cmedium text-4xl pb-2 mt-4">
                About
            </div>
            <div className="md:hidden">
            Fitness isn't just a lifestyle for me—it's my identity. It's been my refuge, helping me overcome internal struggles. Through years of training in NYC, I've learned that true health is about feeling good physically, mentally, and emotionally every day. Now, my focus is on helping you lead a longer, healthier life. Let's work together to find joy and get closer to your goals. Together, we'll build a foundation for robust health and a fulfilling life.
            </div>
            <div className="hidden md:block font-clight text-m lg:text-2xl ">
                <p className="mb-4">
                    Embracing the fitness journey transformed my life in ways I could have never imagined. It's not just a lifestyle for me; it's my identity. Fitness became my sanctuary, a space where I shed insecurities, self-doubt, and a sense of unworthiness that had lingered within me for far too long. In those moments, the weight room wasn't just iron; it was my refuge.
                </p>
                <p className="mb-4">

                    After seven years of training clients in the vibrant heart of New York City, fitness unveiled a profound truth: true health encompasses more than the pursuit of physique. It encompasses how we feel physically, mentally, and emotionally every single day. Elevating physical performance isn't just about looking better; it's about living better.
                </p>
                <p className="mb-4">
                    A pivotal shift occurred. My focus turned to understanding how we can lead longer, healthier lives. This transformation has defined my path ever since.
                </p>
                <p className="mb-4">
                    Guiding others towards their best selves has always been my passion. Drawing from my own journey of trial and error in understanding 'fitness,' I'm committed to helping you find your own path to a harmonious and healthy life. I firmly believe that fitness should be transformative, enriching every facet of your life. Together, through accountability and teamwork, we'll establish habits that will shape your life positively for years to come. Consider it like solving the Rubik’s cube of a balanced fitness lifestyle.
                </p>
                <p className="mb-4">
                    My mission is simple: to assist you in crafting a blueprint for success in your fitness journey. We'll collaborate to unearth what brings you joy and propels you towards your goals. Together, we'll build a foundation for robust health that paves the way for a fulfilling life.
                </p>
                <p className="mb-4">
                    Let's stride forward together towards a brighter, more vibrant life!
                </p>
            </div>
        </div>
    )
};

export default About;