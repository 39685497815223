import React from "react";
import { SelfImage } from "../../assets/images/images"

const Intro = () => {

    return (
        <div>
            <div className="bg-[#050100] flex lg:flex-row flex-col">
                <div className="lg:min-w-[60rem]">
                    <img src={SelfImage} alt="" />
                </div>
                <div className="self-center text-center px-6 pb-4">
                    <span className="text-[#DEDEDE] lg:text-[2.5rem] lg:leading-[3.75rem] font-cbold ">
                    Discipline isn't just about doing the things you love, it's about doing the hard things even when you don't feel like it.
                    </span>
                </div>
            </div>
        </div>
    )
};

export default Intro;